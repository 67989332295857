// CopyToClipboard.js
import React, { useState, useEffect } from "react";
import "./CopyToClipboardStyle.css";
import { useSelector } from "react-redux";
import { CLIENT_TYPES, EMAIL_TEMPLATE } from "../../Middleware/enums";
const CopyToClipboard = ({ texts, businessTypeId, heading }) => {
  const [tooltipState, setTooltipState] = useState(
    new Array(texts.length).fill(false)
  );
  const [tooltipText, setTooltipText] = useState("");
  const [Variables, setVaribles] = useState([]);
  const common = useSelector((state) => state.Storage); //Getting Logged Users Details From Persist Storage of redux hooks

  const handleCopyClick = (text, index) => {
    // Copy text to clipboard
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);

    // Set tooltip text and show it for the clicked text
    setTooltipText("Copied!");
    setTooltipState((prev) => {
      const newState = [...prev];
      newState[index] = true;
      return newState;
    });

    // Hide tooltip after a short delay
    setTimeout(() => {
      setTooltipState((prev) => {
        const newState = [...prev];
        newState[index] = false;
        return newState;
      });
    }, 1000);
  };

  // useEffect(() => {
  //     // Add event listener for focus event on the document
  //     const handleFocus = () => {
  //         // Get the focused element
  //         const focusedElement = document.activeElement;

  //         // Check if the focused element is an input or textarea
  //         if (focusedElement.tagName === 'INPUT' || focusedElement.tagName === 'TEXTAREA') {
  //             // Trigger the paste command
  //             document.execCommand('paste');
  //         }
  //     };

  //     // Add the event listener
  //     document.addEventListener('focus', handleFocus, true);

  //     // Clean up the event listener on component unmount
  //     return () => {
  //         document.removeEventListener('focus', handleFocus, true);
  //     };
  // }, []);

  return (
    <div>
      <p>
        <span className="CopyHeading">{heading}</span>
        {texts.map((text, index) => (
          <span
            key={index}
            style={{ cursor: "pointer" }}
            className="badge badge-secondary"
            onClick={() => handleCopyClick(text, index)}
          >
            {text}
            {tooltipState[index] && (
              <span className="custom-tooltip">{tooltipText}</span>
            )}
          </span>
        ))}
      </p>
    </div>
  );
};

export default CopyToClipboard;

// // CopyToClipboard.js
// import React, { useState } from 'react';
// import './CopyToClipboardStyle.css';

// const CopyToClipboard = ({ texts }) => {
//     const [tooltipState, setTooltipState] = useState(new Array(texts.length).fill(false));
//     const [tooltipText, setTooltipText] = useState('');

//     const handleCopyClick = (text, index) => {
//         // Copy text to clipboard
//         const textarea = document.createElement('textarea');
//         textarea.value = text;
//         document.body.appendChild(textarea);
//         textarea.select();
//         document.execCommand('copy');
//         document.body.removeChild(textarea);

//         // Set tooltip text and show it for the clicked text
//         setTooltipText('Copied!');
//         setTooltipState((prev) => {
//             const newState = [...prev];
//             newState[index] = true;
//             return newState;
//         });

//         // Hide tooltip after a short delay
//         setTimeout(() => {
//             setTooltipState((prev) => {
//                 const newState = [...prev];
//                 newState[index] = false;
//                 return newState;
//             });
//         }, 1000);

//         // Trigger paste at the last cursor position
//         const selection = window.getSelection();
//         if (selection.rangeCount > 0) {
//             const range = selection.getRangeAt(selection.rangeCount - 1);
//             range.deleteContents();

//             // Create a new text node with the copied text
//             const textNode = document.createTextNode(text);
//             range.insertNode(textNode);
//         }
//     };

//     return (
//         <div>
//             {texts.map((text, index) => (
//                 <span
//                     key={index}
//                     className="badge badge-secondary"
//                     onClick={() => handleCopyClick(text, index)}
//                 >
//                     {text}
//                     {tooltipState[index] && <span className="custom-tooltip">{tooltipText}</span>}
//                 </span>
//             ))}
//         </div>
//     );
// };

// export default CopyToClipboard;

// // CopyToClipboard.js
// import React, { useState } from 'react';
// import './CopyToClipboardStyle.css';

// const CopyToClipboard = ({ texts }) => {
//     const [tooltipState, setTooltipState] = useState(new Array(texts.length).fill(false));
//     const [tooltipText, setTooltipText] = useState('');

//     const handleCopyClick = (text, index) => {
//         // Copy text to clipboard
//         const textarea = document.createElement('textarea');
//         textarea.value = text;
//         document.body.appendChild(textarea);
//         textarea.select();
//         document.execCommand('copy');
//         document.body.removeChild(textarea);

//         // Set tooltip text and show it for the clicked text
//         setTooltipText('Copied!');
//         setTooltipState((prev) => {
//             const newState = [...prev];
//             newState[index] = true;
//             return newState;
//         });

//         // Hide tooltip after a short delay
//         setTimeout(() => {
//             setTooltipState((prev) => {
//                 const newState = [...prev];
//                 newState[index] = false;
//                 return newState;
//             });
//         }, 1000);
//     };

//     return (
//         <div>
//             {texts.map((text, index) => (
//                 <span
//                     key={index}
//                     className="badge badge-secondary"
//                     onClick={() => handleCopyClick(text, index)}
//                 >
//                     {text}
//                     {tooltipState[index] && <span className="custom-tooltip">{tooltipText}</span>}
//                 </span>
//             ))}
//         </div>
//     );
// };

// export default CopyToClipboard;
