/* global $ */
import React, { useEffect, useState, useRef, useContext } from "react";
import Select from "react-select";
import SuccessModal from "../../../components/SuccessModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Divider } from "@mui/material";
import { ERROR_MESSAGES } from "../../../components/GlobalMessage";
import { useSelector } from "react-redux";
import {
  UpdateUserSubscriptionPackage,
  GetUserSubscriptionPackageModel,
} from "../../../redux/Services/Subscription/UserListApi";
import { AuthContextProvider } from "../../../AuthContext/AuthContext";
function UserSubscriptionPackageModel(props) {
  const moduleName = "User Subscription Packages";
  // Declare all State
  const [modelAction, setModelAction] = useState("");
  const [requireErrorMessage, setRequireErrorMessage] = useState(false);
  const [subscriptionPackageObj, setSubscriptionPackageObj] = useState({
    ospKeyID: null,
    subscriptionPackageKeyID: null,
    packageName: "",
    prepareQuote: false,
    sendQuote: false,
    prepareContract: false,
    sendContract: false,
    signContract: false,
    eSignaturePerMonth: "",
    yearlyValuePlan: "",
    discountPercentage: "",
    discountPrice: "",
    monthFree: "",
    getMonths: "",
    inPriceOfMonth: "",
    isMailBox: false,
    paymentFrequencyID: null,
  });

  const [
    requireErrorMessageForESignature,
    setRequireErrorMessageForESignature,
  ] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const common = useSelector((state) => state.Storage); //Getting Logged Users Details From Persist Storage of redux hooks
  const [dismissModal, setDismissModal] = useState(null);
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const { setLoader, proposalName, EngagementName } =
    useContext(AuthContextProvider);

  //c]Declare UseEffect
  useEffect(() => {
    setModelAction(props.modelRequestData?.Action === null ? "Add" : "Update"); //Do not change this naming convention
    if (
      props.modelRequestData?.Action !== undefined &&
      props.modelRequestData?.Action !== null
    ) {
      GetSubscriptionPackageModelData(props.modelRequestData.ospKeyID);
    } else {
      SetInitialModelData();
    }
  }, [props.modelRequestData]);

  // C] This function will clear all data from popup model
  const SetInitialModelData = () => {
    setSubscriptionPackageObj({
      ...subscriptionPackageObj,
      ospKeyID: null,
      subscriptionPackageKeyID: null,
      packageName: "",
      prepareQuote: false,
      sendQuote: false,
      prepareContract: false,
      sendContract: false,
      signContract: false,
      eSignaturePerMonth: "",
      yearlyValuePlan: "",
      discountPercentage: "",
      discountPrice: "",
      monthFree: "",
      getMonths: "",
      inPriceOfMonth: "",
      isMailBox: false,
      paymentFrequencyID: null,
    });
    setErrorMessage("");
    setRequireErrorMessage(false);
  };

  // F] Calling CRUD Api here
  // 1) Get Model Data Api
  const GetSubscriptionPackageModelData = async (id) => {
    if (!id) {
      return;
    }
    //..............Subscription package Edit Data Api...................
    try {
      const data = await GetUserSubscriptionPackageModel(id);
      if (data?.data?.statusCode === 200) {
        if (data?.data?.responseData?.data) {
          const ModelData = data?.data?.responseData?.data;
          setSubscriptionPackageObj({
            ...subscriptionPackageObj,
            ospKeyID: ModelData.ospKeyID,
            subscriptionPackageKeyID: ModelData.subscriptionPackageKeyID,
            packageName: ModelData.packageName,
            prepareQuote: ModelData.prepareQuote,
            sendQuote: ModelData.sendQuote,
            prepareContract: ModelData.prepareContract,
            sendContract: ModelData.sendContract,
            signContract: ModelData.sendContract,
            eSignaturePerMonth: ModelData.eSignaturePerMonth,
            yearlyValuePlan: ModelData.yearlyValuePlan,
            discountPercentage: ModelData.discountPercentage,
            paymentFrequencyID: ModelData.paymentFrequencyID,
            discountPrice: ModelData.discountPrice,
            monthFree: ModelData.monthFree,
            getMonths: ModelData.getMonths,
            inPriceOfMonth: ModelData.inPriceOfMonth,
            isMailBox: ModelData.isMailBox,
          });
        }
      } else {
        setErrorMessage(data?.data?.errorMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //2]Add Update Button Click Function
  const SubscriptionPackageAddUpdateBtnClicked = () => {
    //Check Validations will be done here

    if (
      subscriptionPackageObj.packageName === undefined ||
      subscriptionPackageObj.packageName === "" ||
      subscriptionPackageObj.discountPrice === undefined ||
      subscriptionPackageObj.discountPrice === "" ||
      subscriptionPackageObj.discountPrice?.length <= 0
    ) {
      setRequireErrorMessage(true);
      return false; // Return false or handle your error logic here if needed.
    } else {
      setRequireErrorMessage(""); // Clear the error message if there are no errors.
    }
    if (subscriptionPackageObj.sendContract === true) {
      if (Number(subscriptionPackageObj.eSignaturePerMonth) < 1) {
        setRequireErrorMessageForESignature(true);
        return false;
      }
    }
    // Clear the error message and set close to true if there are no errors.
    setErrorMessage("");

    // Preparing Object For Add Update and if any modification then it will done here
    const ApiRequest_ParamsObj = {
      //global level params : fixed
      // Action: props.modelRequestData.Action,
      userKeyID: common.userKeyID,
      organisationKeyID: common.organisationKeyID,
      ospKeyID: props.modelRequestData.ospKeyID,
      //form level params : fixed
      // subscriptionPackageKeyID: props.modelRequestData.subscriptionPackageKeyID, //will change module wise
      subscriptionPackageKeyID: subscriptionPackageObj.subscriptionPackageKeyID, //will change module wise

      //form level params : will change according to module
      packageName: subscriptionPackageObj.packageName,
      prepareQuote: subscriptionPackageObj.prepareQuote,
      sendQuote: subscriptionPackageObj.sendQuote,
      prepareContract: subscriptionPackageObj.prepareContract,
      sendContract: subscriptionPackageObj.sendContract,
      signContract: subscriptionPackageObj.sendContract,
      isMailBox: subscriptionPackageObj.isMailBox,
      eSignaturePerMonth:
        subscriptionPackageObj.eSignaturePerMonth === ""
          ? null
          : subscriptionPackageObj.eSignaturePerMonth,
      yearlyValuePlan:
        subscriptionPackageObj.yearlyValuePlan === ""
          ? null
          : subscriptionPackageObj.yearlyValuePlan,
      discountPercentage:
        subscriptionPackageObj.discountPercentage === ""
          ? null
          : subscriptionPackageObj.discountPercentage,
      discountPrice:
        subscriptionPackageObj.discountPrice === ""
          ? null
          : subscriptionPackageObj.discountPrice,
      monthFree:
        subscriptionPackageObj.monthFree === ""
          ? null
          : subscriptionPackageObj.monthFree,
      getMonths:
        subscriptionPackageObj.getMonths === ""
          ? null
          : subscriptionPackageObj.getMonths,
      inPriceOfMonth:
        subscriptionPackageObj.inPriceOfMonth === ""
          ? null
          : subscriptionPackageObj.inPriceOfMonth,
    };
    AddUpdateSubscriptionPackageData(ApiRequest_ParamsObj);
  };

  // 3) Add Update Subscription package Data Api
  const AddUpdateSubscriptionPackageData = async (ApiRequest_ParamsObj) => {
    setLoader(true);
    try {
      const response = await UpdateUserSubscriptionPackage(
        ApiRequest_ParamsObj
      );
      if (response) {
        setLoader(false);
        if (response?.data?.statusCode === 200) {
          // $('#' + props.id).modal('hide')
          localStorage.removeItem("OrganisationLocalList");
          localStorage.removeItem("subscriptionPlan");
          // uncomment upper code for hide
          if (ApiRequest_ParamsObj.Action === null) {
            setOpenSuccessModal(true);
            props.setIsAddUpdateActionDone(true);
          } else {
            setOpenSuccessModal(true);
            props.setIsAddUpdateActionDone(true);
          }
        } else {
          setErrorMessage(response?.response?.data?.errorMessage);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const HandleCloseModal = () => {
    setOpenSuccessModal(false);
  };

  const HandleClose = () => {
    $("#" + props.id).modal("hide");
    setOpenSuccessModal(false);
  };
  const handlePrepareQuoteChange = (e) => {
    const prepareQuoteValue = e.target.value === "YES";
    setSubscriptionPackageObj({
      ...subscriptionPackageObj,
      prepareQuote: prepareQuoteValue,
      sendQuote: prepareQuoteValue ? subscriptionPackageObj.sendQuote : false,
    });
  };

  const handleSendQuoteChange = (e) => {
    if (subscriptionPackageObj.prepareQuote) {
      setSubscriptionPackageObj({
        ...subscriptionPackageObj,
        sendQuote: e.target.value === "YES",
      });
    }
  };

  const handlePrepareELChange = (e) => {
    const prepareElValue = e.target.value === "YES";
    setSubscriptionPackageObj({
      ...subscriptionPackageObj,
      prepareContract: prepareElValue,
      sendContract: prepareElValue
        ? subscriptionPackageObj.sendContract
        : false,
      eSignaturePerMonth: prepareElValue
        ? subscriptionPackageObj.eSignaturePerMonth
        : "",
      signContract: prepareElValue
        ? subscriptionPackageObj.signContract
        : false,
    });
  };

  const handleSendELChange = (e) => {
    if (subscriptionPackageObj.prepareContract) {
      const sendContract = e.target.value === "YES";
      setSubscriptionPackageObj({
        ...subscriptionPackageObj,
        sendContract: sendContract,
        eSignaturePerMonth: sendContract
          ? subscriptionPackageObj.eSignaturePerMonth
          : "",
        signContract: sendContract
          ? subscriptionPackageObj.signContract
          : false,
      });
    }
  };
  return (
    <div>
      <div
        style={{ display: openSuccessModal && "none" }}
        class={props.class}
        id={props.id}
        tabIndex={props.tabIndex}
        aria-labelledby={props.aria_labelledby}
        aria-hidden={props.aria_hidden}
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div class="modal-dialog modal-md modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header bg-light p-3">
              <h5 class="modal-title" id="exampleModalLabel">
                {modelAction === "Add"
                  ? "Add Subscription Package"
                  : "Update User Subscription Package"}
              </h5>
              <button
                onClick={SetInitialModelData}
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-modal"
              ></button>
            </div>

            <div
              className="modal-body "
              style={{ maxHeight: "28rem", overflow: "auto" }}
            >
              <div className="row">
                <div className="col-lg-6">
                  <label>
                    {moduleName} Name
                    <span className="text-danger">*</span>
                  </label>
                </div>
                <div className="col-12 ">
                  <input
                    style={{ padding: "5px" }}
                    type="text"
                    class="input-text"
                    readOnly
                    placeholder={`${moduleName} Name`}
                    value={subscriptionPackageObj.packageName}
                    onChange={(e) => {
                      const inputValue = e.target.value;
                      const trimmedValue = inputValue.replace(/^\s+/g, "");
                      const isValidName =
                        /^[a-zA-Z]+(?:[a-zA-Z0-9\s]*)$/.test(trimmedValue) &&
                        !/^\d+$/.test(trimmedValue);
                      if (isValidName || trimmedValue === "") {
                        const capitalizedValue =
                          trimmedValue.charAt(0).toUpperCase() +
                          trimmedValue.slice(1);
                        setSubscriptionPackageObj({
                          ...subscriptionPackageObj,
                          packageName: capitalizedValue,
                        });
                      }
                    }}
                  />
                  {requireErrorMessage &&
                  subscriptionPackageObj.packageName === "" ? (
                    <label className="validation">{ERROR_MESSAGES}</label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row p-2">
                <div className="fieldset-group ">
                  <label htmlFor="" className="fieldset-group-label required">
                    {proposalName}
                  </label>
                  <div className="row">
                    <div className="col-6">
                      <FormControl>
                        <FormLabel id="prepare-quote-label">
                          <label>Prepare {proposalName}</label>
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="prepare-quote-label"
                          name="prepare-quote-group"
                          value={
                            subscriptionPackageObj.prepareQuote ? "YES" : "NO"
                          }
                          onChange={handlePrepareQuoteChange}
                        >
                          <FormControlLabel
                            value="YES"
                            control={<Radio />}
                            label="YES"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                    <div className="col-6">
                      <FormControl>
                        <FormLabel id="send-quote-label">
                          <label>Send {proposalName}</label>
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="send-quote-label"
                          name="send-quote-group"
                          value={
                            subscriptionPackageObj.sendQuote ? "YES" : "NO"
                          }
                          onChange={handleSendQuoteChange}
                          disabled={!subscriptionPackageObj.prepareQuote}
                        >
                          <FormControlLabel
                            value="YES"
                            control={<Radio />}
                            label="YES"
                          />
                          <FormControlLabel
                            value="NO"
                            control={<Radio />}
                            label="NO"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="mt-3 col-6">

                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <label>Prepare {proposalName}</label>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={subscriptionPackageObj.prepareQuote ? "YES" : "NO"}
                      onChange={(e) => {
                        setSubscriptionPackageObj({
                          ...subscriptionPackageObj,
                          prepareQuote: e.target.value === "YES" ? true : false,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="YES"
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="mt-3 col-6">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <label>Send {proposalName} </label>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={subscriptionPackageObj.sendQuote ? "YES" : "NO"}
                      onChange={(e) => {
                        setSubscriptionPackageObj({
                          ...subscriptionPackageObj,
                          sendQuote: e.target.value === "YES" ? true : false,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="YES"
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="mt-2 col-6">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <label>Prepare {EngagementName} </label>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={
                        subscriptionPackageObj.prepareContract ? "YES" : "NO"
                      }
                      onChange={(e) => {
                        setSubscriptionPackageObj({
                          ...subscriptionPackageObj,
                          prepareContract:
                            e.target.value === "YES" ? true : false,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="YES"
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>


                <div className="mt-2 col-6">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <label>Send And Digitally Sign The Contract</label>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={
                        subscriptionPackageObj.sendContract ||
                          subscriptionPackageObj.signContract
                          ? "YES"
                          : "NO"
                      }
                      onChange={(e) => {
                        setSubscriptionPackageObj({
                          ...subscriptionPackageObj,
                          sendContract: e.target.value === "YES" ? true : false,
                          // signContract: e.target.value === "YES" ? true : false,
                        });
                      }}
                    >
                      <FormControlLabel
                        value="YES"
                        control={<Radio />}
                        label="YES"
                      />
                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

                <div className="mt-2 col-6">
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      <label>Personalized Outgoing Mailbox</label>
                    </FormLabel>

                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={
                        subscriptionPackageObj.isMailBox ? "YES" : "NO"
                      }
                      onChange={(e) => {
                        setSubscriptionPackageObj({
                          ...subscriptionPackageObj,
                          isMailBox:
                            e.target.value === "YES" ? true : false,
                        });
                      }}
                    >

                      <FormControlLabel
                        value="YES"
                        control={<Radio />}
                        label="YES"
                      />


                      <FormControlLabel
                        value="NO"
                        control={<Radio />}
                        label="NO"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>

              </div> */}

              <div className="fieldset-group">
                <label htmlFor="" className="fieldset-group-label required">
                  {EngagementName}
                </label>

                <div className="row">
                  <div className=" col-6">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        <label>Prepare {EngagementName} </label>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={
                          subscriptionPackageObj.prepareContract ? "YES" : "NO"
                        }
                        // onChange={(e) => {
                        //   setSubscriptionPackageObj({
                        //     ...subscriptionPackageObj,
                        //     prepareContract:
                        //       e.target.value === "YES" ? true : false,
                        //   });
                        // }}
                        onChange={handlePrepareELChange}
                      >
                        <FormControlLabel
                          value="YES"
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value="NO"
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className=" col-6">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        <label>
                          Send And Digitally Sign The {EngagementName}
                        </label>
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={
                          subscriptionPackageObj.sendContract ||
                          subscriptionPackageObj.sendContract
                            ? "YES"
                            : "NO"
                        }
                        // onChange={(e) => {
                        //   setSubscriptionPackageObj({
                        //     ...subscriptionPackageObj,
                        //     sendContract: e.target.value === "YES" ? true : false,
                        //     signContract: e.target.value === "YES" ? true : false,
                        //   });
                        // }}
                        onChange={handleSendELChange}
                      >
                        <FormControlLabel
                          value="YES"
                          control={<Radio />}
                          label="YES"
                        />
                        <FormControlLabel
                          value="NO"
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className=" col-6 p-2">
                    <TextField
                      label="E-Signature per month"
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      size="small"
                      value={
                        subscriptionPackageObj.eSignaturePerMonth === "" ||
                        subscriptionPackageObj.eSignaturePerMonth === null
                          ? 0
                          : subscriptionPackageObj.eSignaturePerMonth
                      }
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        // Remove leading zeros
                        inputValue = inputValue.replace(/^0+/, "");
                        // Remove non-numeric characters except decimal point
                        inputValue = inputValue.replace(/[^\d.]/g, "");
                        // Limit to 12 digits before the decimal point
                        if (inputValue.includes(".")) {
                          const [integerPart, decimalPart] =
                            inputValue.split(".");
                          inputValue = `${integerPart.slice(
                            0,
                            12
                          )}.${decimalPart.slice(0, 2)}`;
                        } else {
                          inputValue = inputValue.slice(0, 12);
                        }
                        // Add commas to the number
                        inputValue = inputValue.replace(
                          /\B(?=(\d{3})+(?!\d))/g,
                          ","
                        );
                        setSubscriptionPackageObj({
                          ...subscriptionPackageObj,
                          eSignaturePerMonth: inputValue,
                        });
                      }}
                      disabled={
                        !(
                          subscriptionPackageObj.sendContract ||
                          subscriptionPackageObj.signContract
                        )
                      }
                    />
                  </div>
                  {requireErrorMessageForESignature &&
                  subscriptionPackageObj.sendContract &&
                  Number(subscriptionPackageObj.eSignaturePerMonth) < 1 ? (
                    <label className="validation mb-1">
                      The E-Signature per month must be at least 1.
                    </label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/* <div className="row">

                <div className="mt-3 col-6">
                  <TextField 
 label={
  <span>
    Yearly value for the plan{" "}
  </span>
}                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    // defaultValue={0}
                    size="small"
                    value={
                      subscriptionPackageObj.yearlyValuePlan === ""
                        ? 0
                        : subscriptionPackageObj.yearlyValuePlan
                    }
                    onChange={(e) => {
                      let inputValue = e.target.value;
                      // Remove leading zeros
                      inputValue = inputValue.replace(/^0+/, "");
                      // Remove non-numeric characters except decimal point
                      inputValue = inputValue.replace(/[^\d.]/g, "");
                      // Limit to 12 digits before the decimal point
                      if (inputValue.includes(".")) {
                        const [integerPart, decimalPart] =
                          inputValue.split(".");
                        inputValue = `${integerPart.slice(
                          0,
                          12
                        )}.${decimalPart.slice(0, 2)}`;
                      } else {
                        inputValue = inputValue.slice(0, 12);
                      }
                      // Add commas to the number
                      inputValue = inputValue.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                      setSubscriptionPackageObj({
                        ...subscriptionPackageObj,
                        yearlyValuePlan: inputValue,
                      });
                    }}
                    
                  />
                                
                </div>
                <div className="mt-3 col-6">
                  <TextField
                    label="E-Signature per month"
                    
                    id="outlined-basic"
                    variant="outlined"
                    type="text"
                    size="small"
                    value={
                      subscriptionPackageObj.eSignaturePerMonth === ""
                        ? 0
                        : subscriptionPackageObj.eSignaturePerMonth
                    }
                    onChange={(e) => {
                      let inputValue = e.target.value;
                      // Remove leading zeros
                      inputValue = inputValue.replace(/^0+/, "");
                      // Remove non-numeric characters except decimal point
                      inputValue = inputValue.replace(/[^\d.]/g, "");
                      // Limit to 12 digits before the decimal point
                      if (inputValue.includes(".")) {
                        const [integerPart, decimalPart] =
                          inputValue.split(".");
                        inputValue = `${integerPart.slice(
                          0,
                          12
                        )}.${decimalPart.slice(0, 2)}`;
                      } else {
                        inputValue = inputValue.slice(0, 12);
                      }
                      // Add commas to the number
                      inputValue = inputValue.replace(
                        /\B(?=(\d{3})+(?!\d))/g,
                        ","
                      );
                      setSubscriptionPackageObj({
                        ...subscriptionPackageObj,
                        eSignaturePerMonth: inputValue,
                      });
                    }}
                    disabled={
                      !(
                        subscriptionPackageObj.sendContract ||
                        subscriptionPackageObj.signContract
                      )
                    }
                  />
                </div>
              </div> */}

              <div className="fieldset-group">
                <label htmlFor="" className="fieldset-group-label required">
                  Other
                </label>

                <div className="row">
                  <div className=" col-6">
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        <label>Personalized Outgoing Mailbox</label>
                      </FormLabel>

                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={subscriptionPackageObj.isMailBox ? "YES" : "NO"}
                        onChange={(e) => {
                          setSubscriptionPackageObj({
                            ...subscriptionPackageObj,
                            isMailBox: e.target.value === "YES" ? true : false,
                          });
                        }}
                      >
                        <FormControlLabel
                          value="YES"
                          control={<Radio />}
                          label="YES"
                        />

                        <FormControlLabel
                          value="NO"
                          control={<Radio />}
                          label="NO"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className=" col-6 p-2">
                    {/* <TextField
  label={
    <span>
      Yearly value for the plan{" "}
    </span>
  }
  id="outlined-basic"
  variant="outlined"
  type="text"
  size="small"
  value={
    subscriptionPackageObj.yearlyValuePlan === null || subscriptionPackageObj.yearlyValuePlan === " "
      ? 0
      : subscriptionPackageObj.yearlyValuePlan.toString().replace(
          /\B(?=(\d{3})+(?!\d))/g,
          ","
        ) || 0
  }
  onChange={(e) => {
    
    let inputValue = e.target.value;
    // Remove non-numeric characters except decimal point
    inputValue = inputValue.replace(/[^\d.]/g, "");
    // If the input is zero, retain the leading zero
    if (inputValue === "0" || inputValue === "0." || inputValue === "" ) {
      inputValue = "0";
    } else {
      // Remove leading zeros
      inputValue = inputValue.replace(/^0+/, "");
    }
    // Limit to 12 digits before the decimal point
    if (inputValue.includes(".")) {
      const [integerPart, decimalPart] =
        inputValue.split(".");
      inputValue = `${integerPart.slice(
        0,
        12
      )}.${decimalPart.slice(0, 2)}`;
    } else {
      inputValue = inputValue.slice(0, 12);
    }
    // Add commas to the number
     inputValue = inputValue;
    // const numericValue = Number(inputValue.replace(/,/g, ""));
    setSubscriptionPackageObj({
      ...subscriptionPackageObj,
      yearlyValuePlan: inputValue,
    });
  }}
/> */}
                    <TextField
                      label={<span>Yearly value for the plan </span>}
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      // defaultValue={0}
                      size="small"
                      value={
                        subscriptionPackageObj?.yearlyValuePlan === "" ||
                        subscriptionPackageObj?.yearlyValuePlan === null
                          ? 0
                          : subscriptionPackageObj?.yearlyValuePlan
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      InputProps={{
                        readOnly: true, // Making the input read-only
                      }}
                      onChange={(e) => {
                        let inputValue = e.target.value;
                        // Remove leading zeros
                        inputValue = inputValue.replace(/^0+/, "");
                        // Remove non-numeric characters except decimal point
                        inputValue = inputValue.replace(/[^\d.]/g, "");
                        // Limit to 12 digits before the decimal point
                        if (inputValue.includes(".")) {
                          const [integerPart, decimalPart] =
                            inputValue.split(".");
                          inputValue = `${integerPart.slice(
                            0,
                            12
                          )}.${decimalPart.slice(0, 2)}`;
                        } else {
                          inputValue = inputValue.slice(0, 12);
                        }
                        // Add commas to the number
                        inputValue = inputValue;
                        setSubscriptionPackageObj({
                          ...subscriptionPackageObj,
                          yearlyValuePlan: inputValue,
                        });
                      }}
                    />

                    {requireErrorMessage &&
                    subscriptionPackageObj.yearlyValuePlan.length <= 0 ? (
                      <label className="validation">
                        The fee per year must be at least 1.
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="row mt-3 ">
                <p>
                  {subscriptionPackageObj.paymentFrequencyID === 1
                    ? "Discounts for annual bills"
                    : ""}
                </p>
                <p>
                  {subscriptionPackageObj.paymentFrequencyID === 4
                    ? "Discounts for Monthly bills"
                    : ""}
                </p>

                <div className="col-6">
                  <div className="row">
                    <div
                      className="col-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="check check_tick"
                        style={{
                          height: "100%",
                          width: "16px",
                        }}
                        id="flexCheckDefault"
                        checked={
                          subscriptionPackageObj.discountPercentage !== ""
                        }
                      />
                    </div>
                    <div className="col-10">
                      <TextField
                        label="Discount(%)"
                        id="outlined-size-small"
                        type="text"
                        size="small"
                        value={
                          subscriptionPackageObj.discountPercentage === "" ||
                          subscriptionPackageObj?.discountPercentage === null
                            ? 0
                            : subscriptionPackageObj.discountPercentage
                        }
                        InputProps={{
                          readOnly: true, // Making the input read-only
                        }}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          // Remove leading zeros
                          inputValue = inputValue.replace(/^0+/, "");
                          // Remove any non-numeric characters except dot
                          inputValue = inputValue.replace(/[^0-9.]/g, "");
                          // Split the input value by decimal point
                          const parts = inputValue.split(".");
                          // Ensure the number of parts is not more than 2
                          if (parts.length > 2) {
                            return;
                          }
                          // If there are more than 1 parts (means decimal point exists)
                          if (parts.length === 2) {
                            // Take only the first two parts and join them with a dot
                            inputValue = parts[0] + "." + parts[1].slice(0, 2);
                          }
                          // Check if the value is a valid number within the range [0, 100]
                          if (
                            isNaN(inputValue) ||
                            inputValue < 0 ||
                            inputValue > 100
                          ) {
                            return;
                          }
                          // Set the state with the sanitized value
                          setSubscriptionPackageObj({
                            ...subscriptionPackageObj,
                            discountPercentage: inputValue,
                          });
                        }}
                      />
                    </div>
                  </div>
                  {/* row end */}
                </div>
                <div className="mt-3 col-6">
                  <div className="row">
                    <div
                      className="col-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="check check_tick"
                        style={{
                          height: "100%",
                          width: "16px",
                        }}
                        id="flexCheckDefault"
                        checked={subscriptionPackageObj.discountPrice !== ""}
                      />
                    </div>
                    <div className="col-10">
                      <TextField
                        label={
                          <span>
                            Discounted Price{" "}
                            <span
                              style={{ fontSize: "20px" }}
                              className="text-danger"
                            >
                              *
                            </span>
                          </span>
                        }
                        id="outlined-size-small"
                        type="text"
                        size="small"
                        value={
                          subscriptionPackageObj.discountPrice === "" ||
                          subscriptionPackageObj?.discountPrice === null
                            ? 0
                            : subscriptionPackageObj.discountPrice
                        }
                        InputProps={{
                          readOnly: true, // Making the input read-only
                        }}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          // Remove leading zeros
                          inputValue = inputValue.replace(/^0+/, "");
                          // Remove non-numeric characters except decimal point
                          inputValue = inputValue.replace(/[^\d.]/g, "");
                          // Limit to 12 digits before the decimal point
                          if (inputValue.includes(".")) {
                            const [integerPart, decimalPart] =
                              inputValue.split(".");
                            inputValue = `${integerPart.slice(
                              0,
                              12
                            )}.${decimalPart.slice(0, 2)}`;
                          } else {
                            inputValue = inputValue.slice(0, 12);
                          }
                          // Add commas to the number
                          inputValue = inputValue.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                          setSubscriptionPackageObj({
                            ...subscriptionPackageObj,
                            discountPrice: inputValue,
                          });
                        }}
                      />
                    </div>
                    {requireErrorMessage &&
                    subscriptionPackageObj.discountPrice.length <= 0 ? (
                      <label className="validation">
                        The discounted price must be at least 1 characters.
                      </label>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="mt-3 col-6">
                  <div className="row">
                    <div
                      className="col-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="check check_tick"
                        style={{
                          height: "100%",
                          width: "16px",
                        }}
                        id="flexCheckDefault"
                        checked={subscriptionPackageObj.monthFree !== ""}
                      />
                    </div>

                    <div class="col-10">
                      <TextField
                        label="Months free"
                        id="outlined-size-small"
                        type="text"
                        size="small"
                        value={
                          subscriptionPackageObj.monthFree === "" ||
                          subscriptionPackageObj?.monthFree === null
                            ? 0
                            : subscriptionPackageObj.monthFree
                        }
                        InputProps={{
                          readOnly: true, // Making the input read-only
                        }}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          // Remove leading zeros
                          inputValue = inputValue.replace(/^0+/, "");
                          // Remove non-numeric characters except decimal point
                          inputValue = inputValue.replace(/[^\d.]/g, "");
                          // Limit to 12 digits before the decimal point
                          if (inputValue.includes(".")) {
                            const [integerPart, decimalPart] =
                              inputValue.split(".");
                            inputValue = `${integerPart.slice(
                              0,
                              12
                            )}.${decimalPart.slice(0, 2)}`;
                          } else {
                            inputValue = inputValue.slice(0, 12);
                          }
                          // Add commas to the number
                          inputValue = inputValue.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                          setSubscriptionPackageObj({
                            ...subscriptionPackageObj,
                            monthFree: inputValue,
                          });
                        }}
                      />
                      {/* <span
                    for="customerName-field"
                    class="fieldset-label required"
                    style={{ marginLeft: "18px" }}
                  >
                    Months free
                  </span> */}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="mt-3 col-6">
                  <div className="row">
                    <div
                      className="col-2"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <input
                        type="checkbox"
                        className="check check_tick"
                        style={{
                          height: "100%",
                          width: "16px",
                        }}
                        id="flexCheckDefault"
                        checked={subscriptionPackageObj.getMonths !== ""}
                      />
                    </div>

                    <div class="col-10">
                      <TextField
                        label="Months for the Price of"
                        id="outlined-size-small"
                        type="text"
                        size="small"
                        value={
                          subscriptionPackageObj.getMonths === "" ||
                          subscriptionPackageObj?.getMonths === null
                            ? 0
                            : subscriptionPackageObj.getMonths
                        }
                        InputProps={{
                          readOnly: true, // Making the input read-only
                        }}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          // Remove leading zeros
                          inputValue = inputValue.replace(/^0+/, "");
                          // Remove non-numeric characters except decimal point
                          inputValue = inputValue.replace(/[^\d.]/g, "");
                          // Limit to 12 digits before the decimal point
                          if (inputValue.includes(".")) {
                            const [integerPart, decimalPart] =
                              inputValue.split(".");
                            inputValue = `${integerPart.slice(
                              0,
                              12
                            )}.${decimalPart.slice(0, 2)}`;
                          } else {
                            inputValue = inputValue.slice(0, 12);
                          }
                          // Add commas to the number
                          inputValue = inputValue.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                          setSubscriptionPackageObj({
                            ...subscriptionPackageObj,
                            getMonths: inputValue,
                          });
                        }}
                      />
                    </div>
                    {/* <div class="col-3">
                  <span
                    for="customerName-field"
                    class="fieldset-label required "
                  >
                    Months for the Price of
                  </span>
                </div> */}
                  </div>
                </div>

                <div className="mt-3 col-6">
                  <div className="row">
                    <div className="col-2"></div>
                    <div class="col-10">
                      <TextField
                        label="Months"
                        id="outlined-size-small"
                        type="text"
                        size="small"
                        value={
                          subscriptionPackageObj.inPriceOfMonth === "" ||
                          subscriptionPackageObj?.inPriceOfMonth === null
                            ? 0
                            : subscriptionPackageObj.inPriceOfMonth
                        }
                        InputProps={{
                          readOnly: true, // Making the input read-only
                        }}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          // Remove leading zeros
                          inputValue = inputValue.replace(/^0+/, "");
                          // Remove non-numeric characters except decimal point
                          inputValue = inputValue.replace(/[^\d.]/g, "");
                          // Limit to 12 digits before the decimal point
                          if (inputValue.includes(".")) {
                            const [integerPart, decimalPart] =
                              inputValue.split(".");
                            inputValue = `${integerPart.slice(
                              0,
                              12
                            )}.${decimalPart.slice(0, 2)}`;
                          } else {
                            inputValue = inputValue.slice(0, 12);
                          }
                          // Add commas to the number
                          inputValue = inputValue.replace(
                            /\B(?=(\d{3})+(?!\d))/g,
                            ","
                          );
                          setSubscriptionPackageObj({
                            ...subscriptionPackageObj,
                            inPriceOfMonth: inputValue,
                          });
                        }}
                      />
                    </div>
                    {/* <div class="col-2">
                  <span
                    for="customerName-field"
                    class="fieldset-label required"
                  >
                    Months
                  </span>
                </div> */}
                  </div>
                </div>
              </div>

              <label
                style={{ display: "flex", justifyContent: "center" }}
                className="validation"
              >
                {errorMessage}
              </label>
              {/* <label
                style={{ display: "flex", justifyContent: "center" }}
                className="validation"
              >
                {errorMessage === "Driver Name Already Exists" &&
                  `Driver name ${subscriptionPackageObj.packageName} already exists.`}
              </label> */}
            </div>
            {/* Row end */}

            <div class="modal-footer">
              <div class="hstack gap-2 justify-content-end">
                <button
                  onClick={() => {
                    SetInitialModelData();
                  }}
                  type="button"
                  class="btn btn-md btn-light"
                  data-bs-dismiss="modal"
                >
                  <span>Cancel</span>
                </button>
                <button
                  type="submit"
                  class="btn btn-md btn-success create-item-btn"
                  onClick={SubscriptionPackageAddUpdateBtnClicked}
                >
                  <span>
                    {modelAction === "Add" ? "Add " + moduleName : "Save"}
                  </span>
                </button>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </div>
      <SuccessModal
        handleClose={HandleClose}
        setDismissModal={setDismissModal}
        setOpenSuccessModal={setOpenSuccessModal}
        openSuccessModal={openSuccessModal}
        modelAction={modelAction}
        message={"Subscription package " + subscriptionPackageObj.packageName}
      />
    </div>
  );
}

export default UserSubscriptionPackageModel;
