import React from "react";
import "./PaginationCss.css";

function PaginationComponent({ totalPages, currentPage, onPageChange }) {
  const getDisplayedPageNumbers = () => {
    const maxPagesToShow = 3;
    const middlePage = Math.floor(maxPagesToShow / 2);

    let startPage;

    if (totalPages <= maxPagesToShow) {
      // When there are fewer pages than maxPagesToShow
      startPage = 1;
    } else if (currentPage <= middlePage) {
      startPage = 1;
    } else if (currentPage >= totalPages - middlePage) {
      startPage = totalPages - maxPagesToShow + 1;
    } else {
      startPage = currentPage - middlePage;
    }

    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    const displayedPageNumbers = [];

    while (startPage <= endPage) {
      displayedPageNumbers.push(startPage);
      startPage++;
    }

    return displayedPageNumbers;
  };

  const handlePageChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      onPageChange(pageNumber); // Call the onPageChange prop with the selected page number
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1); // Call onPageChange with the previous page number
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1); // Call onPageChange with the next page number
    }
  };

  const displayedPageNumbers = getDisplayedPageNumbers();

  return (
    <div className="fixed-bottom container">
      <div className="row align-items-center gy-2 text-center text-sm-start mb-4">
        <div className="col-sm-auto">
          <ul className="pagination pagination-separated pagination-sm  gap-1 justify-content-center justify-content-sm-start">
            <li
              className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              onClick={previousPage}
            >
              <a href="#" className="page-link">
                <b>
                  <i className="mdi mdi-chevron-left"></i>
                </b>
              </a>
            </li>
            {displayedPageNumbers.map((pageNumber) => (
              <li
                className={`page-item ${
                  currentPage === pageNumber ? "active" : ""
                }`}
                onClick={() => handlePageChange(pageNumber)}
                key={pageNumber}
              >
                <a href="#" className="page-link">
                  {pageNumber}
                </a>
              </li>
            ))}
            <li
              className={`page-item ${
                currentPage === totalPages ? "disabled" : ""
              }`}
              onClick={nextPage}
            >
              <a href="#" className="page-link">
                <b>
                  <i className="mdi mdi-chevron-right"></i>
                </b>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PaginationComponent;
