const ClientIndividualVariables = [
    "$Client.FirstName$",
    "$Client.LastName$",
    "$Client.Email$",
    "$Client.Phone$",
    "$Client.Address$",
    "$Client.AddressWithLineBreak$"
]

const ClientSoleTraderVariables = [
    "$Client.SoleTrader.FirstName$",
    "$Client.SoleTrader.LastName$",
    "$Client.SoleTrader.Email$",
    "$Client.SoleTrader.Phone$",
    "$Client.TradingName$",
    "$Client.TradingAddress$",
    "$Client.TradingAddressWithLineBreak$",
    "$Client.SoleTrader.Address$",
    "$Client.SoleTrader.AddressWithLineBreak$"
]

const ClientPartnerShipVariables = [
    "$Client.Partner.Name$",
    "$Client.Partner.FirstName$",
    "$Client.Partner.LastName$",
    "$Client.Partner.Email$",
    "$Client.Partner.Phone$",
    "$Client.TradingName$",
    "$Client.TradingAddress$",
    "$Client.TradingAddressWithLineBreak$",
    "$Client.Partner.Address$",
    "$Client.Partner.AddressWithLineBreak$"
];


const ClientLLpVariables = [
    "$Client.Officer.Name$",
    "$Client.Officer.FirstName$",
    "$Client.Officer.LastName$",
    "$Client.Officer.Email$",
    "$Client.Officer.Phone$",
    "$Client.Officer.Address$",
    "$Client.Officer.AddressWithLineBreak$",
    "$Client.TradingName$",
    "$Client.TradingAddress$",
    "$Client.TradingAddressWithLineBreak$",
    "$Client.Company.Name$",
    "$Client.Company.Number$",
    "$Client.Company.RegisteredAddress$",
    "$Client.Company.RegisteredAddressWithLineBreak$"
];


const ClientCompanyVariables = [
    "$Client.Officer.Name$",
    "$Client.Officer.FirstName$",
    "$Client.Officer.LastName$",
    "$Client.Officer.Email$",
    "$Client.Officer.Phone$",
    "$Client.Officer.Address$",
    "$Client.Officer.AddressWithLineBreak$",
    "$Client.TradingName$",
    "$Client.TradingAddress$",
    "$Client.TradingAddressWithLineBreak$",
    "$Client.Company.Name$",
    "$Client.Company.Number$",
    "$Client.Company.RegisteredAddress$",
    "$Client.Company.RegisteredAddressWithLineBreak$"
];
export default {
    ClientIndividualVariables,
    ClientSoleTraderVariables,
    ClientPartnerShipVariables,
    ClientLLpVariables,
    ClientCompanyVariables
}